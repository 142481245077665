import { styled } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

export const AuthViewWrapper = styled(Paper)(() => ({
  display: 'flex'
}))

export const BackgroundContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'stretch',
  alignContent: 'stretch',
  minHeight: '100vh',
  justifyContent: 'stretch'
}))

export const Background = styled('div')(({ showBackground }) => ({
  display: 'flex',
  backgroundImage: showBackground
    ? "linear-gradient(180deg, rgba(42, 118, 226, 0), hsla(0, 0%, 83.1%, 0.15) 80%, hsla(0, 0%, 100%, 0.5)), url('/static/images/DropMock_Mainstream_Background_v10.jpg')"
    : 'none',
  backgroundPosition: '100% 50%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'scroll',
  flex: '1 1',
  justifyContent: 'center',
  alignItems: 'center'
}))
